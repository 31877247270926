@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'ClashRegular';
    src: url('./assets/fonts/ClashDisplayRegular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'ClashMedium';
    src: url('./assets/fonts/ClashDisplayMedium.otf') format('opentype');
    font-weight: medium;
    font-style: medium;
  }

  @font-face {
    font-family: 'SatoshiRegular';
    src: url('./assets/fonts/Satoshi-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'SatoshiBold';
    src: url('./assets/fonts/Satoshi-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: bold;
  }
  @font-face {
    font-family: 'SatoshiMedium';
    src: url('./assets/fonts/Satoshi-Medium.otf') format('opentype');
    font-weight: medium;
    font-style: medium;
  }